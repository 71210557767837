<template>
  <div id="home">
            <navbar homepage="false"></navbar>
            <div class="newsletter-container">
                <div class="newsletter-left-column">
                    <h3>Archive</h3>
                    <ul>
                        <li>
                            2018
                            <ul>
                                <li><a class="archive-date active" href="01-jan-2018">January</a></li>
                                <li><a class="archive-date" href="01-feb-2018">February</a></li>
                                <li><a class="archive-date" href="01-mar-2018">March</a></li>
                                <li><a class="archive-date" href="01-apr-2018">April</a></li>
                                <li><a class="archive-date" href="01-may-2018">May</a></li>
                                <li><a class="archive-date" href="01-jun-2018">June</a></li>
                                <li><a class="archive-date" href="01-jul-2018">July</a></li>
                                <li><a class="archive-date" href="01-aug-2018">August</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="newsletter-right-column">
                    <div class="newsletter-right-column-content">
                        <h2>MUSKLE Monthly Newsletter #001</h2>
                        <p class="newsletter-subtitle">
                            “Why can’t I see results?”
                        </p>
                        <p class="newsletter-date">
                            (Jan 2018)
                        </p>
                        <p class="newsletter-paragraph">
                            This is a question I get asked regularly when training new clients.<br />
                            “Well Susan, that’s because you’ve only been working-out for 25 minutes…”
                        </p>
                        <p class="newsletter-paragraph">
                            Hi Everyone, welcome to MUSKLE’s first monthly newsletter aimed at educating and helping all individuals achieve their goals. Whether that’s to lose 20kgs, run a marathon, or anything in-between. Over the next 12 months I’ll piece together some (hopefully) interesting and insightful articles which you can utilise in your daily life.
                        </p>
                        <p class="newsletter-paragraph">
                            When it comes to making visible results, there’s a few things you need to consider.<br />
                            They are; <span class="italic">Choices</span>, <span class="italic">Consistency</span>, and <span class="italic">Continuation</span>.<br />
                            Once you’ve made the right choice by picking the apple over the chocolate bar, and you can do that consistently for 21 days, you’ll form a habit. Habits are the foundation on which results are made. A continuation of this habit long-term will yield the desired visible outcomes.
                        </p>
                        <p class="newsletter-paragraph">
                            To put this theory into practise, try crossing your arms. Take note of which arm is on top. Now swap it so the other arm is on top. Feels weird, right? You’ve formed this habit over years, but never thought about it - It’s natural. The same applies for just about everything, including eating and exercise. 
                        </p>
                        <p class="newsletter-paragraph">
                            If you want the Instagram-worthy booty, or ripped 6-pack that all the guys and girls seem to have on the beaches in Miami, it’s not going to happen overnight. Start by making good choices, and then back that up for another 20 days. Form the foundation, and then continue that for another 20-30 days. I’ll guarantee you’ll see results.
                            Bring on 2018! I’ll finish #001 with a personal favourite from Tony Robins:
                        </p>
                        <p class="newsletter-paragraph">
                            “Change is <span class="italic">inevitable</span><br />
                            Progress is <span class="italic">optional</span> ”
                        </p>
                        <p class="newsletter-preview">
                            Next month sneak peak - what motivates you?
                        </p>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import Navbar from '../components/NavBar.vue'

export default {
  name: 'app',
  components: {
    Navbar
  }
}
</script>

<style>
body {
  padding: 0;
  margin: 0;
}
#home {
  margin: 0;
  padding: 0;
}
  h2 {
      font-family: 'Roboto';
      margin-bottom: 25px;
      text-align: center;
  }
  p {
      font-family: 'Roboto', sans-serif;
  }
  ul {
      list-style-type: none;
  }
  li {
      padding: 5px 0;
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
  }
  .archive-date {
      color: #000;
      text-decoration: none;
  }
  .archive-date:hover {
      text-decoration: underline;
  }
  .active {
      font-weight: 900;
  }
  .newsletter-container {
      margin-top: 125px;
      display: flex;
  }
  .newsletter-left-column {
      flex-grow: 1;
      padding: 10px;
      margin-top: 20px;
      margin-left: 20px;
      max-width: 160px;
  }
  .newsletter-right-column {
      display: flex;
      flex-grow: 5;
      justify-content: center;
      margin-right: 80px;
  }
  .newsletter-right-column-content {
      margin: 50px 100px;
      border: 3px double #ddd;
      padding: 0px 30px;
      max-width: 595px;
  }
  .newsletter-subtitle {
      font-size: 20px;
      font-weight: 300;
  }
  .newsletter-date {
      font-size: 14px;
      font-weight: 500;
  }
  .newsletter-paragraph {
      font-size: 16px;
      font-weight: 300;
      line-height: 28px;
  }
  .newsletter-preview {
      font-size: 16px;
      font-weight: 300;
      text-transform: uppercase;
      text-align: right;
      padding-top: 40px;
  }
  .italic {
      font-style: italic;
  }

  @media only screen and (max-width: 750px) {
      h2 {
          font-size: 18px;
      }
      .newsletter-container {
          flex-direction: column;
      }
      .newsletter-right-column {
          margin-right: 0;
      }
      .newsletter-right-column-content {
          margin: 10px 10px;
          padding: 0 10px;
      }
      .newsletter-subtitle {
          font-size: 16px;
      }
      .newsletter-date {
          font-size: 13px;
      }
      .newsletter-paragraph {
          font-size: 14px;
      }
      .newsletter-preview {
          font-size: 13px;
      }
  }
</style>
